import { makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
    width: "100%",
  },

  btnWrapper: {
    position: "100%",
    margin: "10px",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 140,
  },
}));

const IntegrationModal = ({ open, onClose, integrationId }) => {
  const classes = useStyles();
  const history = useHistory();

  const initialState = {
    status: false,
    name: "",
    token: "",
    userToken: "",
    entity: [],
    category: [],
  };

  const user = useContext(AuthContext);
  const [integration, setIntegration] = useState(initialState);
  // const [btnEntity, setBtnEntity] = useState(false);
  // const [btnCategory, setBtnCategory] = useState(false);

  useEffect(() => {
    const fetchIntegration = async () => {
      if (!integrationId) return;
      try {
        const { data } = await api.get(`/integrations/${integrationId}`);

        setIntegration((prevState) => {
          return { ...prevState, ...data };
        });
        if (data.integration.entity === null) {
          //btnEntity(false);
        }
        if (data.integration.category === null) {
          //btnCategory(false);
        }
      } catch (err) {
        toast.error(err);
      }
    };

    fetchIntegration();
  }, [integrationId, open]);

  const handleClose = () => {
    onClose();
    setIntegration(initialState);
  };

  const handleSaveIntegration = async (values) => {
    const integrationData = { ...values };
    try {
      if (integrationId) {
        await api.put(`/integrations/${integrationId}`, integrationData);
        history.push("/integrations");
      } else {
        await api.post("/integrations", integrationData);
      }
      toast.success(i18n.t("integration.success"));
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  const handleChangeState = (event) => {
    setIntegration({
      ...integration,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeEntity = async () => {
    if (
      integration.name === "GLPI" &&
      integration.status &&
      integration.entity === null
    ) {
      try {
        const respDataEntity = await api.get(`/glpi/itilEntities`);

        if (respDataEntity) {
          toast.success("Gravado as Entidades na base de Dados");
        }
        // setBtnEntity(true);
        handleClose();
        history.push("/integrations");
      } catch (error) {
        // setBtnEntity(false);
      }
    } else {
      alert("Dados ainda não Carregados Entidade");
    }
  };

  const handleChangeCategory = async (e) => {
    if (
      integration.name === "GLPI" &&
      integration.status &&
      integration.category === null
    ) {
      try {
        const respDataCategory = await api.get(`/glpi/itilcategory`);
        if (respDataCategory) {
          toast.success("Categorias gravadas na base de Dados");
        }
        // setBtnCategory(true);
        handleClose();
        history.push("/integrations");
      } catch (error) {
        toast.error("Erro ao ler a API REST");
        // setBtnCategory(false);
      }
    } else {
      toast.error("Dados ainda não Carregados Categorias");
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {integrationId
            ? `${i18n.t("integration.integrationModal.title.edit")}`
            : `${i18n.t("integration.integrationModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={integration}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveIntegration(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.multFieldLine}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={handleChangeState}
                        checked={values.status}
                        name="status"
                      />
                    }
                    label={
                      i18n.t("integration.integrationModal.state") +
                      ": " +
                      (values.status ? "Ativo" : "Inativo")
                    }
                  />
                </div>
                {user?.user?.profile === "super" ? (
                  <div className={classes.multFieldLine}>
                    <Field
                      as={TextField}
                      label={i18n.t("integration.integrationModal.name")}
                      name="name"
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </div>
                ) : (
                  <>
                    <div className={classes.multFieldLine}>
                      <Field
                        as={TextField}
                        label={i18n.t("integration.integrationModal.name")}
                        autoFocus
                        name="name"
                        disabled
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </div>
                  </>
                )}
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("integration.integrationModal.url")}
                    name="url"
                    error={touched.url && Boolean(errors.url)}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    name="token"
                    variant="outlined"
                    margin="dense"
                    label={"App" + i18n.t("integration.integrationModal.token")}
                    error={touched.token && Boolean(errors.token)}
                    type="text"
                    fullWidth
                  />
                </div>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    name="userToken"
                    variant="outlined"
                    margin="dense"
                    label={i18n.t("integration.table.userToken")}
                    error={touched.userToken && Boolean(errors.userToken)}
                    type="text"
                    fullWidth
                  />
                </div>
                <div className={classes.multFieldLine}>
                  {integration.entity === null && (
                    <FormControlLabel
                      control={
                        <Button
                          color="primary"
                          size="small"
                          variant="outlined"
                          className={classes.btnWrapper}
                          onClick={handleChangeEntity}
                        >
                          Carregar Entidades
                        </Button>
                      }
                    />
                  )}
                  {integration.category === null && (
                    <FormControlLabel
                      control={
                        <Button
                          color="primary"
                          size="small"
                          variant="outlined"
                          className={classes.btnWrapper}
                          onClick={handleChangeCategory}
                        >
                          Carregar Categorias
                        </Button>
                      }
                    />
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("integration.integrationModal.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {integrationId
                    ? `${i18n.t("integration.integrationModal.save")}`
                    : `${i18n.t("integration.integrationModal.cancel")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default IntegrationModal;
