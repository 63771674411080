import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import { IconButton, InputAdornment, FormControlLabel, Switch, Tooltip } from "@material-ui/core"; //MenuItem
import { Colorize } from "@material-ui/icons";
import { AccountTreeOutlined } from "@material-ui/icons";
import HelpIcon from "@material-ui/icons/Help";
// import useContactGroups from "../../hooks/useContactsGroup";



const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	textFieldNumber: {
		marginRight: theme.spacing(1),
		maxWidth: "80px",
		flex: 1,
	},
	textFieldColor: {
		marginRight: theme.spacing(1),
		maxWidth: "160px",
		flex: 1,
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},
	selectLineFull: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
		width: "100%",
	},
	btnWrapper: {
		position: "relative",
	},
	buttonColorError: {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main,
	},
	buttonProgress: {
		color: theme.palette.secondary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 100,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
		'&:hover': {
			filter: 'brightness(120%)',
		},
	},
	chip: {
		margin: 2,
	},
}));

const QueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
	greetingMessage: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId }) => {
	const classes = useStyles();
	const initialState = {
		sequencial: "",
		name: "",
		color: "",
		greetingMessage: "",
		isNotifyContactGroup: false,
		notifyContactGroup: "",
	};

	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
	const [queue, setQueue] = useState(initialState);
	const greetingRef = useRef();
	// Estado para armazenar os grupos
	//const [contactGroups, setContactGroups] = useState([]);

	// Função para buscar os grupos da aba de contatos
	// const fetchContactGroups = async () => {
	// 	try {
	// 	const response = await api.get("/contactGroups");
	// 	const groups = response.data; // Supondo que a resposta tenha a lista de grupos
	// 	setContactGroups(groups);
	// 	} catch (err) {
	// 	toastError(err);
	// 	}
	// };

	useEffect(() => {
		(async () => {
			
			if (!queueId) return;
			try {
				const { data } = await api.get(`/queue/${queueId}`);
				setQueue(prevState => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				toastError(err);
			}
		})();
		//fetchContactGroups();
		return () => {
			setQueue({
				sequencial: "",
				name: "",
				color: "",
				greetingMessage: "",
				isNotifyContactGroup: false,
				notifyContactGroup: "",
			});
		};
	}, [queueId, open]);

	const handleClose = () => {
		onClose();
		setQueue(initialState);
	};

	const handleSaveQueue = async values => {
		try {
			if (queueId) {
				await api.put(`/queue/${queueId}`, values);
			} else {
				await api.post("/queue", values);
			}
			toast.success("Fila salva com sucesso");
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					<AccountTreeOutlined /><span></span>
					{queueId
						? `${i18n.t("queueModal.title.edit")}`
						: `${i18n.t("queueModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={queue}
					enableReinitialize={true}
					validationSchema={QueueSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveQueue(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
							<div className={classes.multFieldLine}>		
								<Field
									as={TextField}
									label={i18n.t("queueModal.form.name")}
									autoFocus
									name="name"
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									className={classes.textField}
								/>
								<Field
									as={TextField}
									label={i18n.t("queueModal.form.sequencial")}
									name="sequencial"
									error={touched.sequencial && Boolean(errors.sequencial)}
									helperText={touched.sequencial && errors.sequencial}
									variant="outlined"
									margin="dense"
									className={classes.textFieldNumber}
								/>
								<Field
									as={TextField}
									label={i18n.t("queueModal.form.color")}
									name="color"
									id="color"
									onFocus={() => {
										setColorPickerModalOpen(true);
										greetingRef.current.focus();
									}}
									error={touched.color && Boolean(errors.color)}
									helperText={touched.color && errors.color}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<div
													style={{ backgroundColor: values.color }}
													className={classes.colorAdorment}
												></div>
											</InputAdornment>
										),
										endAdornment: (
											<IconButton
												size="small"
												color="default"
												onClick={() => setColorPickerModalOpen(true)}
											>
												<Colorize />
											</IconButton>
										),
									}}
									variant="outlined"
									margin="dense"
									className={classes.textFieldColor}
								/>
								<ColorPicker
									open={colorPickerModalOpen}
									handleClose={() => setColorPickerModalOpen(false)}
									onChange={color => {
										values.color = color;
										setQueue(() => {
											return { ...values, color };
										});
									}}
								/>
								</div>
								<div>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.greetingMessage")}
										type="greetingMessage"
										multiline
										inputRef={greetingRef}
										rows={5}
										fullWidth
										name="greetingMessage"
										error={
											touched.greetingMessage && Boolean(errors.greetingMessage)
										}
										helperText={
											touched.greetingMessage && errors.greetingMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<div className={classes.multFieldLine}>
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="isNotifyContactGroup"
												checked={values.isNotifyContactGroup}
											/>
										}
										label={i18n.t("queueModal.form.notifyContactGroup.name")}
									/>	
										<Tooltip title={i18n.t("queueModal.form.notifyContactGroup.title")}>
											<IconButton aria-label="notifyContactGroup">
												<HelpIcon />
											</IconButton>
										</Tooltip>
								</div>
								<div className={classes.multFieldLine}>		
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.notifyContactGroup.number")}
										autoFocus
										name="notifyContactGroup"
										error={touched.notifyContactGroup && Boolean(errors.notifyContactGroup)}
										helperText={touched.notifyContactGroup && errors.notifyContactGroup}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
								</div>

								{/* <Field
									as={TextField}
									select
									label={i18n.t("queueModal.form.group")}
									name="groupId"
									error={touched.groupId && Boolean(errors.groupId)}
									helperText={touched.groupId && errors.groupId}
									variant="outlined"
									margin="dense"
									className={classes.textField}
									>
									<MenuItem value="" disabled>
										Selecione um grupo
									</MenuItem>
									{contactGroups.map(group => (
										<MenuItem key={group.id} value={group.id}>
										{group.name}
										</MenuItem>
									))}
								</Field> */}


							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("queueModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{queueId
										? `${i18n.t("queueModal.buttons.okEdit")}`
										: `${i18n.t("queueModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default QueueModal;
