import {
  Button,
  Select,
  Switch,
  TextField,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import React, { useContext, useEffect, useState } from "react";
import openSocket from "../../services/socket-io";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import { toast } from "react-toastify";
import { systemVars } from "../../../package.json";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";

import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import DraftsIcon from "@material-ui/icons/Drafts";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import GroupIcon from "@material-ui/icons/Group";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
import MessageIcon from "@material-ui/icons/Message";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PhoneDisabledIcon from "@material-ui/icons/PhoneDisabled";
import SettingsIcon from "@material-ui/icons/Settings";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { Box, Tab, Tabs } from "@mui/material";
import { Scrollbars } from "react-custom-scrollbars";
import CopyToClipboard from "../../components/CopyToClipboard";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      width: "99%",
      flexWrap: "wrap",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "600px",
      flexWrap: "wrap",
    },
  },
  container: {
    padding: "16px 24px",
  },
  mainPaper: {
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "99%",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "600px",
    },
  },
  title: {},
  titleH2: {
    fontSize: "1.25rem",
    fontWeight: "500",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textCampaignsContainer: {
    width: "100%",
  },
  content: {
    padding: "0 24px 16px",
  },
  formActions: {
    flex: "0 0 auto",
    display: "flex",
    padding: "8px",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  option: {
    display: "inline-flex",
    width: "100%",
    margin: "8px 0",
  },
  settingLabel: {
    padding: "5px 0",
  },
  settingOption: {
    marginLeft: "auto",
    minWidth: "128px",
  },
  settingOptionCritical: {
    marginLeft: "auto",
    minWidth: "480px",
  },
  switchOption: {
    marginLeft: "auto",
    minWidth: "40px",
  },
  icons: {
    marginLeft: 10,
    marginRight: 15,
  },
  settingTextField: {
    margin: "0 0 0 auto",
    maxWidth: "109px",
  },
  divider: {
    margin: "16px 0",
    border: "none",
    height: "1px",
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
  tabsvMenu: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 224,
  },
  tabsv: {
    borderRight: `1px solid #ccc`, //${theme.palette.divider}
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 1,
    height: 24,
    padding: 0,
    margin: theme.spacing(0),
    marginLeft: 7,
    marginRight: 10,
    marginBottom: 1,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #ff0000",
    },
  },
  thumb: {
    width: 22,
    height: 22,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#FF0000",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function TabPanelMenu(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{ height: "99%" }}
    >
      {value === index && (
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Settings = () => {
  const { user } = useContext(AuthContext);

  const classes = useStyles();
  const [settings, setSettings] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const { data } = await api.get("/settings");
        setSettings(data);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, []);

  useEffect(() => {
    const socket = openSocket();

    socket.on("settings", (data) => {
      if (data.action === "update") {
        setSettings((prevState) => {
          const aux = [...prevState];
          const settingIndex = aux.findIndex(
            (s) => s.name === data.setting.name
          );
          aux[settingIndex].value = data.setting.value;
          return aux;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue);
  };

  const handleChangeSetting = async (e) => {
    const selectedValue = e.target.checked ? "enabled" : "disabled";
    const settingKey = e.target.name;

    try {
      await api.put(`/settings/${settingKey}`, {
        value: selectedValue,
      });
      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const handleChangeSettingOption = async (e) => {
    const selectedValue = e.target.value;
    const settingKey = e.target.name;

    try {
      await api.put(`/settings/${settingKey}`, {
        value: selectedValue,
      });
      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
  };
  const getSettingValue = (name) => {
    const { value } = settings.find((s) => s.name === name);
    return value;
  };

  const goToSupport = () => {
    window.open(systemVars.settingsSupportLink, "_blank");
  };

  const goToReleases = () => {
    window.open(systemVars.settingsReleases, "_blank");
  };

  return (
    <MainContainer>
      <MainHeader>
        <Title>{i18n.t("settings.settings.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={goToSupport}
            disabled
            // disabled goToReleases
          >
            {i18n.t("settings.support")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={goToReleases}
            // disabled
          >
            {i18n.t("settings.goToReleases")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Scrollbars>
        <div className={classes.container}>
          <div>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="Tabs"
              indicatorColor="primary"
              left="true"
            >
              <Tab icon={<SettingsIcon />} iconPosition="start" />
              <Tab icon={<WhatsAppIcon />} iconPosition="start" />
              {user.profile === "super" && (
                <Tab icon={<DeveloperBoardIcon />} iconPosition="start" />
              )}
            </Tabs>
            <TabPanelMenu value={selectedTab} index={0}>
              {/* Conteúdo da Aba Geral */}
              <div className={classes.Title}>
                <Title>Configurações Gerais</Title>
              </div>
              {/* Opção Create User */}
              <div className={classes.option}>
                <IOSSwitch
                  className={classes.switchOption}
                  id="userCreation-setting"
                  disabled
                  name="userCreation"
                  checked={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue("userCreation") === "enabled"
                  }
                  onChange={handleChangeSetting}
                ></IOSSwitch>
                <PersonAddIcon className={classes.icons} />
                <Typography variant="h6">
                  {i18n.t("settings.settings.userCreation.name")}
                </Typography>
              </div>
              <Typography>
                {i18n.t("settings.settings.userCreation.note")}
              </Typography>
              {/* Opção Create sideMenu */}
              <div className={classes.option}>
                <IOSSwitch
                  className={classes.switchOption}
                  id="sideMenu-setting"
                  name="sideMenu"
                  checked={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue("sideMenu") === "enabled"
                  }
                  onChange={handleChangeSetting}
                ></IOSSwitch>
                <MenuOutlinedIcon className={classes.icons} />
                <Typography variant="h6">
                  {i18n.t("settings.settings.sideMenu.name")}
                </Typography>
              </div>
              <Typography>
                {i18n.t("settings.settings.sideMenu.note")}
              </Typography>
              <div className={classes.option}>
                <IOSSwitch
                  className={classes.switchOption}
                  id="isTimeNewCreateTicket-setting"
                  name="isTimeNewCreateTicket"
                  checked={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue("isTimeNewCreateTicket") === "enabled"
                  }
                  onChange={handleChangeSetting}
                ></IOSSwitch>
                <MessageIcon className={classes.icons} />
                <Typography variant="h6">
                  {i18n.t("settings.settings.isTimeNewCreateTicket.name")}
                </Typography>
                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="timeCreateNewTicket-setting"
                  name="timeCreateNewTicket"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue("timeCreateNewTicket")
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSettingOption}
                >
                  <option value="10">
                    {i18n.t("settings.settings.timeCreateNewTicket.options.10")}
                  </option>
                  <option value="30">
                    {i18n.t("settings.settings.timeCreateNewTicket.options.30")}
                  </option>
                  <option value="60">
                    {i18n.t("settings.settings.timeCreateNewTicket.options.60")}
                  </option>
                  <option value="300">
                    {i18n.t(
                      "settings.settings.timeCreateNewTicket.options.300"
                    )}
                  </option>
                  <option value="1800">
                    {i18n.t(
                      "settings.settings.timeCreateNewTicket.options.1800"
                    )}
                  </option>
                  <option value="3600">
                    {i18n.t(
                      "settings.settings.timeCreateNewTicket.options.3600"
                    )}
                  </option>
                  <option value="7200">
                    {i18n.t(
                      "settings.settings.timeCreateNewTicket.options.7200"
                    )}
                  </option>
                  <option value="21600">
                    {i18n.t(
                      "settings.settings.timeCreateNewTicket.options.21600"
                    )}
                  </option>
                  <option value="43200">
                    {i18n.t(
                      "settings.settings.timeCreateNewTicket.options.43200"
                    )}
                  </option>
                </Select>
              </div>
              <Typography variant="body1">
                {i18n.t("settings.settings.isTimeNewCreateTicket.note")}
              </Typography>
            </TabPanelMenu>
            <TabPanelMenu value={selectedTab} index={1}>
              {/* Conteúdo da Aba Tickets */}
              {/* Opção Create call */}
              <div className={classes.option}>
                <IOSSwitch
                  className={classes.switchOption}
                  id="call-setting"
                  name="call"
                  checked={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue("call") === "enabled"
                  }
                  onChange={handleChangeSetting}
                ></IOSSwitch>
                <PhoneDisabledIcon className={classes.icons} />
                <Typography variant="h5">
                  {i18n.t("settings.settings.call.name")}
                </Typography>
              </div>
              <Typography>{i18n.t("settings.settings.call.note")}</Typography>
              {/* Opção Create checkMsgIsGroup */}
              <div className={classes.option}>
                <IOSSwitch
                  className={classes.switchOption}
                  id="checkMsgIsGroup-setting"
                  name="checkMsgIsGroup"
                  checked={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue("checkMsgIsGroup") === "enabled"
                  }
                  onChange={handleChangeSetting}
                ></IOSSwitch>
                <GroupIcon className={classes.icons} />
                <Typography variant="h5">
                  {i18n.t("settings.settings.checkMsgIsGroup.name")}
                </Typography>
              </div>
              <Typography variant="body1">
                {i18n.t("settings.settings.checkMsgIsGroup.note")}
              </Typography>
              {/* Opção Create transfTicket */}
              <div className={classes.option}>
                <IOSSwitch
                  className={classes.switchOption}
                  id="transfTicket-setting"
                  name="transfTicket"
                  checked={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue("transfTicket") === "enabled"
                  }
                  onChange={handleChangeSetting}
                ></IOSSwitch>
                <MessageIcon className={classes.icons} />
                <Typography variant="h5">
                  {i18n.t("settings.settings.transfTicket.name")}
                </Typography>
              </div>
              <Typography variant="body1">
                {i18n.t("settings.settings.transfTicket.note")}
              </Typography>

              {/* Opção Create notificationGroupNewTicket */}
              <div className={classes.option}>
                <IOSSwitch
                  className={classes.switchOption}
                  id="notificationGroupNewTicket-setting"
                  name="notificationGroupNewTicket"
                  checked={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue("notificationGroupNewTicket") === "enabled"
                  }
                  onChange={handleChangeSetting}
                ></IOSSwitch>
                <DraftsIcon className={classes.icons} />
                <Typography variant="h5">
                  {i18n.t("settings.settings.notificationGroupNewTicket.name")}
                </Typography>
              </div>
              <Typography variant="body1">
                {i18n.t("settings.settings.notificationGroupNewTicket.note")}
              </Typography>
              {/* Opção Create notificationGroupNewTicket */}
              <div className={classes.option}>
                <IOSSwitch
                  className={classes.switchOption}
                  id="ticketHistoryPull-setting"
                  name="ticketHistoryPull"
                  checked={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue("ticketHistoryPull") === "enabled"
                  }
                  onChange={handleChangeSetting}
                ></IOSSwitch>
                <DraftsIcon className={classes.icons} />
                <Typography variant="h5">
                  {i18n.t("settings.settings.ticketHistoryPull.name")}
                </Typography>
              </div>
              <Typography variant="body1">
                {i18n.t("settings.settings.ticketHistoryPull.note")}
              </Typography>
              {/* Opção Create notificationGroupNewTicket */}
              <div className={classes.option}>
                <IOSSwitch
                  className={classes.switchOption}
                  id="lastTicketLinkToUser-setting"
                  name="lastTicketLinkToUser"
                  checked={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue("lastTicketLinkToUser") === "enabled"
                  }
                  onChange={handleChangeSetting}
                ></IOSSwitch>
                <EmojiPeopleIcon className={classes.icons} />
                <Typography variant="h5">
                  {i18n.t("settings.settings.lastTicketLinkToUser.name")}
                </Typography>
              </div>
              <Typography variant="body1">
                {i18n.t("settings.settings.lastTicketLinkToUser.note")}
              </Typography>
              {/* Opção Create notificationGroupNewTicket */}
              <div className={classes.option}>
                <IOSSwitch
                  className={classes.switchOption}
                  id="acceptTicketSendMessageUserId-setting"
                  name="acceptTicketSendMessageUserId"
                  checked={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue("acceptTicketSendMessageUserId") ===
                      "enabled"
                  }
                  onChange={handleChangeSetting}
                ></IOSSwitch>
                <DraftsIcon className={classes.icons} />
                <Typography variant="h5">
                  {i18n.t(
                    "settings.settings.acceptTicketSendMessageUserId.name"
                  )}
                </Typography>
              </div>
              <Typography variant="body1">
                {i18n.t("settings.settings.acceptTicketSendMessageUserId.note")}
              </Typography>
            </TabPanelMenu>
            <TabPanelMenu value={selectedTab} index={2}>
              {/* Conteúdo da Aba Super */}
              <Typography variant="h6">
                {i18n.t("settings.settings.limits.title")}
              </Typography>
              <div className={classes.option}>
                <Typography variant="h6">
                  {i18n.t("settings.settings.limits.evaluationPeriod")}
                </Typography>
                <TextField
                  margin="dense"
                  variant="outlined"
                  id="evaluationPeriod-setting"
                  name="evaluationPeriod"
                  value="15/08/22"
                  className={classes.settingTextField}
                  disabled
                />
              </div>
              <Typography variant="body1">
                {i18n.t("settings.settings.limits.evaluationPeriodDescription")}
              </Typography>
              <div className={classes.option}>
                <Typography variant="h6">
                  {i18n.t("settings.settings.limits.maxUsers")}
                </Typography>
                {user.profile === "super" && (
                  <TextField
                    margin="dense"
                    variant="outlined"
                    id="maxUsers-setting"
                    name="maxUsers"
                    value={
                      settings &&
                      settings.length > 0 &&
                      getSettingValue("maxUsers")
                    }
                    className={classes.settingTextField}
                    onChange={handleChangeSettingOption}
                  />
                )}
                {user.profile !== "super" && (
                  <TextField
                    margin="dense"
                    variant="outlined"
                    id="maxUsers-setting"
                    name="maxUsers"
                    value={
                      settings &&
                      settings.length > 0 &&
                      getSettingValue("maxUsers")
                    }
                    className={classes.settingTextField}
                    onChange={handleChangeSettingOption}
                    disabled
                  />
                )}
              </div>
              <Typography variant="body1">
                {i18n.t("settings.settings.limits.userMaxDescription")}
              </Typography>
              <div className={classes.option}>
                <Typography variant="h6">
                  {i18n.t("settings.settings.limits.maxConnections")}
                </Typography>
                <TextField
                  margin="dense"
                  variant="outlined"
                  id="maxConnections-setting"
                  name="maxConnections"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue("maxConnections")
                  }
                  className={classes.settingTextField}
                  onChange={handleChangeSettingOption}
                />
              </div>
              <Typography variant="body1">
                {i18n.t("settings.settings.limits.userMaxDescription")}
              </Typography>
              <div className={classes.divider}></div>
              {/* Opção Node JS applicationState */}
              <div className={classes.option}>
                <MenuOutlinedIcon className={classes.icons} />
                <Typography variant="h6">
                  {i18n.t("settings.settings.application.applicationState")}
                </Typography>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Node JS Environment"
                  value={process.env.NODE_ENV.toUpperCase()}
                  className={classes.settingOptionCritical}
                  readOnly
                />
                <CopyToClipboard
                  content={process.env.NODE_ENV}
                  color="secondary"
                />
              </div>
              {/* Opção Node JS applicationUrlBackend */}
              <div className={classes.option}>
                <MenuOutlinedIcon className={classes.icons} />
                <Typography variant="h6">
                  {i18n.t(
                    "settings.settings.application.applicationUrlBackend"
                  )}
                </Typography>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Backend URL"
                  value={process.env.REACT_APP_BACKEND_URL}
                  className={classes.settingOptionCritical}
                  readOnly
                />
                <CopyToClipboard
                  content={process.env.REACT_APP_BACKEND_URL}
                  color="secondary"
                />
              </div>
              {/* Opção Node JS apiToken */}
              <div className={classes.option}>
                <MenuOutlinedIcon className={classes.icons} />
                <Typography variant="h6">
                  {i18n.t("settings.settings.application.apiToken")}
                </Typography>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Token Api"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue("userApiToken")
                  }
                  className={classes.settingOptionCritical}
                  readOnly
                />
                <CopyToClipboard
                  content={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue("userApiToken")
                  }
                  color="secondary"
                />
              </div>
            </TabPanelMenu>
          </div>
        </div>
      </Scrollbars>
    </MainContainer>
  );
};

export default Settings;
