import React, { useState } from 'react';
import { Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, CircularProgress, Stack, Divider } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import Title from '../../components/Title';
import api from '../../services/api';

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'contact',
    label: 'Contato',
  },
  {
    id: 'note',
    label: 'Nota NPS',
  },
  {
    id: 'attendant',
    label: 'Atendente',
  },

  {
    id: 'finalizado_em',
    label: 'Finalizado em',
  },



];

const EnhancedTableHead = (props) => {

  const { order, orderBy, onRequestSort, showAcess } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>


        {headCells.map((headCell) => {

          if (headCell.id === 'Quant' && showAcess === false) {
            console.log('não mostrar acessos', headCell.id, !showAcess, ' => ', headCell.id === 'Quant' && !showAcess);
            return null;
          };

          return (



            <TableCell
              sx={{ fontWeight: 'bold' }}
              key={headCell.id}
              align={'left'}
              padding={'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)} >

                {headCell.label}

                {orderBy === headCell.id ? (
                  <>
                    <Box Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  </>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead >
  );
}


export const TableTicketsAvaliation = (props) => {

  const { dataInicial, dataFinal, selectedUser, search } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('finalizado_em');
  const [isFetch, setIsFetch] = useState(false);

  const [listNotesTickets, setListNotesTickets] = useState([]);


  useEffect(() => {
    handleGetTicketNotes();
  }, [search])



  const handleGetTicketNotes = async () => {
    setIsFetch(true);
    try {
      const { data } = await api.get(`/dashboard-tickets-notes?initialDate=${format(dataInicial, 'yyyy-MM-dd')}&finalDate=${format(dataFinal, 'yyyy-MM-dd')}&interval=todas&&attendanceId=${selectedUser}`)
      setListNotesTickets(data);
    } catch (error) {
      console.log('deu erro => ', error)
    } finally {
      setIsFetch(false);
    }
  };

  const formatDate = (date) => {

    if (!date) return;

    const formatedDate = format(parseISO(date), 'dd/MM/yyyy HH:mm');

    return formatedDate
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listNotesTickets.length) : 0;


  if (isFetch) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 2, height: 450, }}>
        <CircularProgress sx={{ color: '#0e1e36' }} color='inherit' size={50} />
        <Typography variant="h5" sx={{ color: '#0e1e36' }} gutterBottom>
          Carregando dados...
        </Typography>
      </Box>
    )
  }


  return (
    <Box sx={{ position: 'relative' }}>

      <>
        {listNotesTickets.length === 0 ?
          <>
            <Stack direction={'column'} spacing={2} sx={{ height: 200, }}>
              <Title variant="h6" component="div" >Avaliações por tickets</Title>
              <Typography sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                Nenhuma avaliação encontrada para o período selecionado
              </Typography>
            </Stack>
          </>
          :
          <>

            <Title variant="h6" component="div" sx={{ py: 2, }}> Avaliações por tickets</Title>

            <Divider />

            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={'medium'}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}

                />
                <TableBody>

                  {stableSort(listNotesTickets, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          sx={{ '&:hover': { cursor: 'pointer' } }}
                        >
                          <TableCell align="left" scope="row"  >{row.id}</TableCell>

                          <TableCell align="left">{row.contact}</TableCell>
                          <TableCell align="left">{row.note}</TableCell>
                          <TableCell align="left">{row.attendant}</TableCell>
                          <TableCell align="left">{formatDate(row?.finalizado_em)}</TableCell>

                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={listNotesTickets.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        }
      </>
    </Box>
  )
}
